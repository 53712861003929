/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { I18nextProvider } from "react-i18next"
import i18next from "../../i18next"

const Layout = ({ children }) => {

  return (
    <I18nextProvider i18n={i18next}>
      {children}
    </I18nextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
